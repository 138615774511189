import axios from "axios";
import React, { useEffect, useState } from "react";
import { ensureArray } from "../../utils/ensureArray";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import AnimationUserExam from "../../assets/anime2.json";
import { Toaster, toast } from "sonner";
import EmptyExams from "../EmptyExams/EmptyExams";
const UserExam = () => {
  const [exam, setExam] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .post(
        `https://panel.digitalsatexam.az/api/exam/user-exams`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setExam(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <div className="container my-5">
        <div className="row items-center justify-center">
          <div className="col-xl-8">
            <div className="flex items-center justify-center">
              <img
                className="w-20 h-20 animate-spin"
                src="https://www.svgrepo.com/show/448500/loading.svg"
                alt="Loading icon"
              />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div>
      {Object.keys(ensureArray(exam?.exams)).length > 0 ? (
        <div className="container">
          <div className="row g-4">
            {exam?.exams ? (
              ensureArray(exam?.exams).map((element, index) => (
                <div className="col-xl-3 my-5" key={index}>
                  <div className="border-1  text-center rounded-md shadow-lg overflow-hidden  ">
                    <h1 className="text-xl py-2 bg-slate-700 text-white color-black">
                      {element?.title}
                    </h1>{" "}
                    <img
                      src={element?.image}
                      alt={element?.title}
                      className="h-[200px] w-full object-cover"
                    />
                    <button>
                      <Link
                        className="bg-gray-700 w-full  hover:bg-gray-800 my-4 block text-white  py-2 px-4 rounded"
                        // to={`/exam/${element?.id}`}
                        to={`/user-exams/${element?.id}`}
                      >
                        Start your Exam!
                      </Link>
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <EmptyExams />
            )}
          </div>
        </div>
      ) : (
        <EmptyExams />
      )}
    </div>
  );
};

export default UserExam;
