import {
  COffcanvas,
  COffcanvasHeader,
  CCloseButton,
  COffcanvasBody,
} from "@coreui/react";
import { useCart } from "react-use-cart";
import axios from "axios";
import { useState } from "react";
import { toast } from "sonner";
import SpinnerLoading from "../LaodingSpinner";
import "./card.scss";
const CardItems = ({ removeItems, visible, setVisible }) => {
  const { isEmpty, totalUniqueItems, items } = useCart();
  const [promoCode, setPromoCode] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCheckout = (e) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("promocode", promoCode || 0);
    items.map((element, index) => {
      formdata.append(`exam_id[${index}]`, element.id);
    });
    formdata.append(
      "user_id",
      JSON.parse(localStorage.getItem("user-verify")).id
    );
    axios
      .post("https://panel.digitalsatexam.az/api/exam/checkout", formdata)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Please, wait...");
          setVisible(false);
          setLoading(false);
          localStorage.removeItem("react-use-cart");
          items.forEach((item) => {
            localStorage.removeItem(`product_added_${item.id}`);
          });

          setTimeout(() => {
            window.location.replace(res?.data?.page_url);
          }, 2500);
        }
      })
      .catch((err) => {
        toast.error("Sifariş verilərkən xəta baş verdi");
        setLoading(false);
        setVisible(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChange = (e) => {
    setPromoCode(e.target.value);
  };

  return (
    <>
      <COffcanvas
        backdrop="static"
        placement="start"
        visible={visible}
        className="bg-slate-800 w-80 h-full overflow-y-auto"
        onHide={() => setVisible(false)}
      >
        <COffcanvasHeader>
          <div>
            <h5> {isEmpty ? "Your cart is empty" : ""}</h5>
            <h2 className="text-white">Cart ({totalUniqueItems})</h2>
          </div>

          <CCloseButton
            className="text-reset text-white"
            onClick={() => setVisible(false)}
          />
        </COffcanvasHeader>
        <COffcanvasBody>
          <div className="overflow-x-auto  my-4">
            <ul className="p-0">
              {items.map((item, index) => (
                <div
                  key={index}
                  className="flex my-2 shadow-md items-center border-spacing-1"
                >
                  <li className="p-2 ">
                    <img
                      src={
                        item.image ||
                        "https://images.unsplash.com/photo-1606326608606-aa0b62935f2b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8dGVzdHxlbnwwfHwwfHx8MA%3D%3D"
                      }
                      className="w-10 h-10 md:w-10 md:h-10 rounded-full  "
                      alt={item?.title}
                    />
                  </li>
                  <li
                    className="text_clapms w-40 md:w-40 px-6 py-0 text-sm font-bold text-white"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  ></li>

                  <li className="px-6 py-4 flex items-center text-sm font-bold text-white">
                    {item.price * item.quantity}
                    <span className="text-gray-600 text-sm text-white font-bold">
                      {" "}
                      ₼
                    </span>
                  </li>
                  <li className="px-6 py-4">
                    <button
                      onClick={() => {
                        removeItems(item.id);
                      }}
                      className="btn btn-danger text-white"
                    >
                      X
                    </button>
                  </li>
                </div>
              ))}
              <form className="w-full max-w-sm">
                <div className="flex items-center  border-b-2 border-teal-500 py-2">
                  <input
                    className="appearance-none bg-transparent border-none w-full text-white mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="text"
                    placeholder="please, enter promocode"
                    aria-label="Full name"
                    name="promocode"
                    value={promoCode}
                    onChange={handleChange}
                  />
                  {/* <button
                      className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                      type="button"
                    >
                      Apply
                    </button> */}
                </div>
              </form>
              <button
                disabled={isEmpty || loading}
                onClick={handleCheckout}
                className={
                  isEmpty
                    ? "btn btn-dark disabled cursor-not-allowed my-3 w-full rounded-md py-2"
                    : "bg-blue-600 my-3 w-full py-2 text-white rounded-md "
                }
              >
                {loading ? <SpinnerLoading /> : "Checkout"}
              </button>
            </ul>
          </div>
        </COffcanvasBody>
      </COffcanvas>
    </>
  );
};
export default CardItems;
