import React from "react";

import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";

const MathSection = ({ heading, openMatch, setOpenMathch, className }) => {
  return (
    <div>
      <div className="bg-gray-800 px-2 py-2 rounded-md text-white flex items-center gap-1">
        {heading}
        {openMatch ? (
          <RiArrowUpSLine
            onClick={() => {
              setOpenMathch(false);
            }}
            size={20}
          />
        ) : (
          <RiArrowDownSLine
            size={20}
            onClick={() => {
              setOpenMathch(true);
            }}
          />
        )}
        {openMatch && (
          <div
            className={`bg-white p-8 shadow-md rounded-lg max-w-[800px] w-full max-h-[500px] overflow-y-scroll ${className}`}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-700">Math</h2>
              <div className="flex items-center">
                {/* <span className="text-lg font-semibold text-gray-700 mr-2">
                  0:00
                </span> */}
                {/* <button className="bg-gray-200 text-gray-700 px-3 py-1 rounded-md text-sm hover:bg-gray-300">
                  Hide
                </button> */}
              </div>
            </div>
            <div className="text-sm text-gray-600">
              <p>
                The questions in this section address a number of important math
                skills.
              </p>
              <p>
                Use of a calculator is permitted for all questions. A reference
                sheet, calculator, and these directions can be accessed
                throughout the test.
              </p>
              <ul className="list-disc pl-5 space-y-1">
                <li>All variables and expressions represent real numbers.</li>
                <li>Figures provided are drawn to scale.</li>
                <li>All figures lie in a plane.</li>
                <li>
                  The domain of a given function f is the set of all real
                  numbers x for which f(x) is a real number.
                </li>
              </ul>
              <p className="mt-4">
                For multiple-choice questions, solve each problem and choose the
                correct answer from the choices provided.
              </p>
              <p>
                For student-produced response questions, solve each problem and
                Enter your answer as described below:
              </p>
              <ul className="list-disc pl-5 space-y-1">
                <li>
                  If you find more than one correct answer, Enter only one
                  answer.
                </li>
                <li>
                  You can Enter up to 5 characters for a positive answer and up
                  to 6 characters (including the negative sign) for a negative
                  answer.
                </li>
                <li>
                  If your answer is a fraction that doesn’t fit in the provided
                  space, Enter the decimal equivalent.
                </li>
                <li>
                  If your answer is a decimal that doesn’t fit in the provided
                  space, Enter it by truncating or rounding at the fourth digit.
                </li>
                <li>
                  If your answer is a mixed number (such as 3 1/2), Enter it as
                  an improper fraction (7/2) or its decimal equivalent (3.5).
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MathSection;
