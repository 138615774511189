import React from "react";
import Lottie from "lottie-react";
import LoadingModuleJson from "../../assets/loadingmodule2.json";

const LoadingModule = ({ module }) => {
  return (
    <div>
      <Lottie
        style={{
          width: "100%",
          height: "400px",
          maxWidth: "400px",
          margin: "0 auto",
        }}
        animationData={LoadingModuleJson}
        loop={true}
      />
      <h1 className="text-center">Loading New Exams {module}</h1>
    </div>
  );
};

export default LoadingModule;
