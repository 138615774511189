import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Modal from "react-modal";
import { IoClose, IoBookmark } from "react-icons/io5";
const QuestionNavigate = ({
  questions,
  setActiveQuestion,
  bookmarks,
  answeredQuestions,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "10px",
      padding: "10px",
    },
  };

  const [open, setOpen] = useState(false);
  const HandleOpen = () => {
    setOpen(true);
  };
  const Close = () => {
    setOpen(false);
  };
  return (
    <div>
      {" "}
      <button>
        <MdKeyboardArrowUp
          onClick={() => {
            setOpen(true);
          }}
          size={20}
        />
      </button>
      <Modal isOpen={open} onRequestClose={Close} style={customStyles}>
        <div className="flex justify-between w-full my-2">
          <p className="text-md font-semibold">Reading and Writing Questions</p>
          <button
            className=" text-white max-w-[50px] cursor-pointer"
            onClick={() => {
              Close();
            }}
          >
            <IoClose color="black" size={20} />
          </button>
        </div>
        <div>
          <p></p>
        </div>
        <div>
          <div className="flex gap-2 flex-wrap max-w-lg">
            {questions.map((element, index) => (
              <button
                key={`button-${index}`}
                onClick={() => {
                  setActiveQuestion(index - 1);
                  Close();
                }}
                className={
                  answeredQuestions.some(
                    (item) => item.question_id === element.id
                  )
                    ? "relative w-10 h-10 cursor-pointer bg-blue-600 text-white border-dotted border-2 border-gray-700 flex   items-center justify-center"
                    : " relative w-10 h-10 cursor-pointer  border-dotted border-2 border-gray-700 flex text-black  items-center justify-center"
                }
              >
                {++index}
                {bookmarks[element.id] && (
                  <div className="absolute top-[-4px] right-[-3px]">
                    <IoBookmark color="#FF0000" size={18} />
                  </div>
                )}
              </button>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QuestionNavigate;
Modal.setAppElement("#root");
