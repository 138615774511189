// BookmarkToggle.jsx
import React from "react";
import { IoBookmarkOutline, IoBookmark } from "react-icons/io5";

const BookmarkToggle = ({ isBookmarked, onToggle }) => (
  <button
    onClick={onToggle}
    className={`p-2 rounded-lg flex  items-center  gap-2 w-[250px] ${
      isBookmarked ? "text-black" : "text-gray-900"
    }  focus:outline-none`}
  >
    {isBookmarked ? <IoBookmark size={25} /> : <IoBookmarkOutline size={25} />}
    <span className="max-md:hidden"> Add to Bookmark</span>
  </button>
);

export default BookmarkToggle;
