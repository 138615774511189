const Overview = ({
  totalQuestions,
  CorrectAnswer,
  IncorrectAnswers,
  EmptyAnswers,
}) => {
  return (
    <div className="bg-blue-100 p-4 my-3 rounded-lg shadow-md flex justify-around items-center">
      <div className="text-center">
        <p className="text-3xl max-md:text-xl font-semibold text-gray-800">
          {totalQuestions}
        </p>
        <p className="text-gray-600 max-md:text-sm">Total Questions</p>
      </div>
      <div className="border-l-2 border-gray-300 h-12 w-1"></div>
      <div className="text-center">
        <p className="text-3xl max-md:text-xl font-semibold text-gray-800">
          {CorrectAnswer}
        </p>
        <p className="text-gray-600 max-md:text-sm ">Correct Answers</p>
      </div>
      <div className="border-l-2 border-gray-300 w-2 h-12"></div>
      <div className="text-center">
        <p className="text-3xl max-md:text-xl font-semibold text-gray-800">
          {IncorrectAnswers}
        </p>
        <p className="text-gray-600 max-md:text-sm">Incorrect Answers</p>
      </div>
      <div className="border-l-2 border-gray-300 w-2 h-12"></div>
      <div className="text-center">
        <p className="text-3xl max-md:text-xl font-semibold text-gray-800">
          {EmptyAnswers}
        </p>
        <p className="text-gray-600 max-md:text-sm">OMITTED</p>
      </div>
    </div>
  );
};

export default Overview;
