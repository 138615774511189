import React, { useTransition } from "react";
import VerificationInput from "react-verification-input";
import "./style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import SpinnerLoading from "../../components/LaodingSpinner";
const LoginVerification = () => {
  const [value, setValue] = React.useState("");
  const token = localStorage.getItem("token");
  const navigation = useNavigate();
  const [pending, startTransation] = useTransition();

  const HandleSubmit = () => {
    axios
      .post(
        "https://panel.digitalsatexam.az/api/auth/email-verification-for-register",
        {
          verify_code: value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("user-verify", JSON.stringify(res?.data?.user));
          navigation("/home");
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="max-w-[430px] w-full border p-4 rounded-md shadow-md">
        <h1 className="text-center text-slate-800 text-xl my-2">
          Please, open your email and enter your verification code here!
        </h1>
        <VerificationInput
          onChange={(e) => {
            setValue(e);
          }}
          inputField={{
            onChange: (e) => {
              setValue(e.nativeEvent.target.value);
            },
          }}
          length={6}
          validChars="0-9"
          removeDefaultStyles
          classNames={{
            container: "container",
            character: "character",
            characterInactive: "character--inactive",
            characterSelected: "character--selected",
            characterFilled: "character--filled",
          }}
        />
        <button
          disabled={pending}
          onClick={() => {
            startTransation(() => {
              HandleSubmit();
            });
          }}
          className="bg-blue-800 w-full flex justify-center items-center text-white rounded-md p-2 mt-3"
        >
          {pending ? <SpinnerLoading /> : "Verify"}
        </button>
      </div>
    </div>
  );
};

export default LoginVerification;
