import React, { useEffect, useRef } from "react";
import Desmos from "desmos";
import { FaCalculator } from "react-icons/fa";
import {
  COffcanvas,
  COffcanvasHeader,
  CCloseButton,
  COffcanvasBody,
} from "@coreui/react";
import { useDrag } from "../UseDrag/UseDrag";

const Calculate = () => {
  const draggableRef = useRef(null);

  const { position, handleMouseDown } = useDrag({
    ref: draggableRef,
  });
  const desmosContainer = useRef(null);
  const [visible, setVisible] = React.useState(false);
  let calculator;
  useEffect(() => {
    calculator = Desmos.GraphingCalculator(desmosContainer.current);

    return () => {
      calculator.destroy();
    };
  }, []);

  return (
    <React.Fragment>
      {" "}
      <button
        onClick={() => {
          setVisible(true);
        }}
        className="flex items-center gap-3 bg-slate-700 rounded-md px-2 py-2 text-white"
      >
        {" "}
        <FaCalculator />
        Calculator
      </button>
      <COffcanvas
        onMouseDown={handleMouseDown}
        backdrop="static"
        placement="end"
        visible={visible}
        className="bg-white draggable  h-full max-h-[700px] max-w-[400px] w-full"
        onHide={() => setVisible(false)}
        ref={draggableRef}
        style={{
          top: position.y,
          left: position.x,
        }}
      >
        <COffcanvasHeader>
          <div className="draggable-panel" onMouseDown={handleMouseDown}>
            Draggable Calculator
          </div>

          <CCloseButton
            className="text-reset"
            onClick={() => setVisible(false)}
          />
        </COffcanvasHeader>
        <COffcanvasBody className="draggable-content">
          <div
            ref={desmosContainer}
            style={{ width: "100%", height: "100%" }}
          ></div>
        </COffcanvasBody>
      </COffcanvas>
    </React.Fragment>
  );
};

export default Calculate;
