import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ResultTable from "../../components/ResultTable/ResultTable";
import ModalContext from "../../components/ModalContext/ModalContext";
import { IoCloseOutline } from "react-icons/io5";
import Overview from "./Overwiew";
import { getExamResultDetail, handleReport } from "../../utils/ApiHelper";
import { toast } from "sonner";
import { CheckStatus } from "../../utils/helper";
import LoadingSusponse from "../../components/Atoms/LoadingSusponse";
import FeedbackQuestions from "../../components/FeedbackQuestions/FeedbackQuestions";

const ResultDetail = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const typesetMathContent = () => {
      if (window.MathJax) {
        window.MathJax.typesetClear();
        window.MathJax.typesetPromise()
          .then(() => {})
          .catch((err) => {
            console.error("MathJax typesetting failed: ", err);
          });
      }
    };

    const timer = setTimeout(() => {
      typesetMathContent();
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  }, [details, selectedItem]);

  useEffect(() => {
    setLoading(true);
    getExamResultDetail({
      exam_id: id,
    })
      .then((res) => {
        if (res.status === 200) {
          setDetails(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  const HandleReport = (feedback) => {
    const Payload = {
      report: feedback,
      question_id: selectedItem?.id,
    };
    handleReport({
      data: Payload,
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div className="container mt-5">
      <h1 className="text-2xl font-semibold text-gray-800 dark:text-white">
        The details of your exam
      </h1>
      <p className="text-gray-400 dark:text-gray-300 my-2">
        You can review your answers here.
      </p>
      <Overview
        totalQuestions={details.common_answer}
        CorrectAnswer={details?.correct_answer}
        IncorrectAnswers={details?.incorrect_answer}
        EmptyAnswers={details?.empty_answer}
      />
      {loading ? (
        <LoadingSusponse />
      ) : (
        <ResultTable
          data={details?.answers?.questions}
          setOpen={setOpen}
          HandleOpenModal={(item) => {
            setOpen(true);
            setSelectedItem(item);
          }}
        />
      )}
      <Suspense fallback={<LoadingSusponse />}>
        <ModalContext modalIsOpen={open} open={open} setOpen={setOpen}>
          {selectedItem && (
            <div className="max-w-[1250px] max-md:max-w-[500px] w-full max-h-[700px] overflow-y-scroll">
              <div className="flex justify-between px-2 py-2 items-center">
                <h1
                  className="text-xl font-semibold text-gray-800 dark:text-white"
                  dangerouslySetInnerHTML={{ __html: selectedItem?.module }}
                />
                <button
                  className=""
                  onClick={() => {
                    setOpen(false);
                    setSelectedItem(null); // Reset the selected item when closing the modal
                  }}
                >
                  <IoCloseOutline size={24} className="mr-2" />
                </button>
              </div>
              <div className="my-3">
                <FeedbackQuestions
                  onSubmited={(feedback) => {
                    HandleReport(feedback);
                  }}
                />
              </div>
              <div className="flex gap-3 justify-between w-full flex-col max-md:flex-wrap">
                <div className="flex flex-col w-full gap-2 max-md:max-w-full ">
                  <div>
                    <h4 className="text-bold text-sm ">Question:</h4>
                    <div
                      id="math"
                      className="bg-gray-100 text-black p-2 rounded-md  leading-6 options_variant"
                      dangerouslySetInnerHTML={{
                        __html: selectedItem?.title,
                      }}
                    />

                    {Object.keys(selectedItem?.all_variant).length > 2 &&
                      selectedItem?.all_variant?.map((item, idx) => (
                        <div
                          key={idx}
                          className={` relative flex gap-6 justify-between items-stretch w-full`}
                        >
                          <div
                            key={idx}
                            className={`relative flex items-center space-x-2 rounded-xl border-1 px-6 my-2 py-4 w-full cursor-pointer select-none bg-gray-100 text-gray-500`}
                          >
                            <div
                              className={`w-[35px] h-[35px] mr-3 shrink-0 rounded-full   flex items-center justify-center bg-gray-500 text-white`}
                            >
                              {" "}
                              <span className="text-xl font-semibold flex items-center justify-center">
                                {item?.variant}
                              </span>
                            </div>
                            <div
                              id="math"
                              className="flex options_variant"
                              dangerouslySetInnerHTML={{
                                __html: item?.title,
                              }}
                            ></div>
                          </div>
                        </div>
                      ))}

                    <h3 className="text-bold text-sm "> Your Answer:</h3>
                    <h4
                      className={`p-2 rounded-md text-sm ${CheckStatus(
                        selectedItem?.status
                      )}`}
                      dangerouslySetInnerHTML={{
                        __html:
                          selectedItem?.answer === "empty-204"
                            ? "Not Answered"
                            : selectedItem?.answer,
                      }}
                    />
                    <h3 className="text-bold text-sm ">Status:</h3>
                    <h4
                      className={`p-2 rounded-md text-sm ${CheckStatus(
                        selectedItem?.status
                      )}`}
                      dangerouslySetInnerHTML={{
                        __html: selectedItem?.status,
                      }}
                    />
                  </div>
                </div>
                <div className="w-full max-md:max-w-full">
                  <h3 className="text-bold text-sm ">Correct Answer:</h3>
                  <p className="bg-green-100 text-green-500 font-semibold p-2 rounded-md text-md my-2">
                    {selectedItem?.correct_answer?.title}
                  </p>
                  <h3 className="text-bold text-sm bg-slate-200 p-2 inline-block rounded-md">
                    Explanation:
                  </h3>

                  <div
                    className="bg-gray-100 text-gray-500 p-2 rounded-md  leading-6 options_variant"
                    dangerouslySetInnerHTML={{
                      __html: selectedItem?.content,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </ModalContext>
      </Suspense>
    </div>
  );
};

export default ResultDetail;
