import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoutes, PublicRoutes } from "./router";
import NotFound from "./errorcomponent/404";
import { Toaster } from "sonner";

const App = () => {
  return (
    <BrowserRouter>
      <Toaster richColors position="top-right" />
      <Routes>
        {PublicRoutes.map((route, index) => (
          <Route
            // errorElement={<RootBoundary />}
            key={index}
            path={route.path}
            element={route.element}
          />
        ))}
        {PrivateRoutes.map((route, index) => (
          <Route
            // errorElement={<RootBoundary />}
            key={index}
            path={route.path}
            element={route.element}
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
