import React from "react";
import { Link } from "react-router-dom"; // Don't forget to import Link from react-router-dom

const PaymentErrorPage = () => {
  return (
    <div className="container">
      <div className="row items-center justify-center h-screen">
        <div className="col-xl-6">
          <div className="bg-gray-100">
            <div className="bg-white p-6 md:mx-auto">
              <svg
                viewBox="0 0 24 24"
                className="text-red-600 w-16 h-16 mx-auto my-6"
              >
                <path
                  fill="currentColor"
                  d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0ZM13,17h-2v-2h2Zm0-4h-2V5h2Z"
                ></path>
              </svg>
              <div className="text-center">
                <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                  Payment Failed
                </h3>
                <p className="text-red-600 my-2">
                  Oops! Something went wrong with your payment.
                </p>
                <p>Please check your information and try again.</p>
                <div className="py-10 text-center">
                  <Link
                    to="/home"
                    className="px-12 rounded-md bg-red-600 hover:bg-red-500 text-white font-semibold py-3"
                  >
                    Go Back to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentErrorPage;
