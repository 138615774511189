import React from "react";
import UserExam from "../../components/UserExam/UserExam";

const UserExams = () => {
  return (
    <div>
      <UserExam />
    </div>
  );
};

export default UserExams;
