import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "../../../utils/ApiHelper";
import styled from "./navbar.module.scss";
import { CloseMenu, OpenMenu } from "../../../icons/Close";
import Logo from "../../../assets/mainLogo.webp";
import { capitalizeFirstLetterOfEachWord } from "../../../utils/helper";

const Navbar = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const HandleToogleMenu = () => {
    setOpen(!open);
  };
  const handleCloseNavbar = () => {
    setOpen(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      handleCloseNavbar();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const HandleLogout = () => {
    setLoading(true);
    Logout("auth/logout")
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear();
          Navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  };

  const UserData = JSON.parse(localStorage.getItem("user-verify"));
  return (
    <div>
      <nav className="bg-slate-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 max-md:hidden">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Link to="/home">
                  <img
                    src={Logo}
                    alt="logo"
                    className="w-20 h-30  object-contain"
                  />
                </Link>
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline  space-x-4">
                  <Link
                    to="/home"
                    className=" hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    All SAT Exams
                  </Link>
                  <Link
                    to="/user-exams"
                    className=" hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    My Exams
                  </Link>
                  <Link
                    to="/result"
                    className=" hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Exam Results
                  </Link>
                  <Link
                    to="/books"
                    className=" hover:bg-gray-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    All Books
                  </Link>
                  <a
                    href="https://satkurslari.az/sat-haqqinda-bloq/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Blog
                  </a>

                  <button
                    onClick={HandleLogout}
                    className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {loading ? "Loading..." : "Logout"}
                  </button>
                </div>
              </div>
            </div>

            <button className="text-end text-gray-800 bg-white px-3 py-2 rounded-md max-md:hidden">
              {capitalizeFirstLetterOfEachWord(UserData?.name_surname)} 👋
            </button>
          </div>
        </div>

        <div className="container-fluid hidden max-md:block ">
          <div className="row">
            <div className="col-xl-12 p-0">
              <div className={styled.menu_items}>
                <Link to="/">
                  <img
                    className="h-8 w-8"
                    src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                  />
                </Link>
                {open ? (
                  <CloseMenu
                    className={styled.close}
                    onClick={HandleToogleMenu}
                  />
                ) : (
                  <OpenMenu
                    className={styled.open}
                    onClick={HandleToogleMenu}
                  />
                )}

                {open ? (
                  <div className={open ? styled.open_elements : styled.closed}>
                    <div className="self-stretch border-b-[color:var(--colors-border-border-secondary,#E5E7EB)] flex flex-col border-b border-solid items-start bg-slate-800">
                      <div className="items-stretch self-stretch flex w-full justify-between gap-4 mt-3 px-4 flex-col ">
                        <div className="overflow-hidden text-white  text-ellipsis text-base font-semibold leading-6 grow shrink basis-auto">
                          <Link
                            className="no-underline text-white text-ellipsis"
                            to="/user-exams"
                            onClick={handleCloseNavbar}
                          >
                            My Exams
                          </Link>
                        </div>
                      </div>
                      <div className="items-stretch self-stretch flex w-full justify-between gap-4 mt-3 px-4 flex-col ">
                        <div className="overflow-hidden text-white  text-ellipsis text-base font-semibold leading-6 grow shrink basis-auto">
                          <Link
                            target="_blank"
                            to="https://satkurslari.az/sat-haqqinda-bloq/"
                            className="no-underline text-white text-ellipsis"
                          >
                            Blog
                          </Link>
                        </div>
                      </div>
                      <div className="items-stretch self-stretch flex w-full justify-between gap-4 mt-3 px-4 flex-col ">
                        <div className="overflow-hidden text-white  text-ellipsis text-base font-semibold leading-6 grow shrink basis-auto">
                          <Link
                            to="/result"
                            className="no-underline text-white text-ellipsis"
                          >
                            Exams Results
                          </Link>
                        </div>
                      </div>
                      <div className="items-stretch self-stretch flex w-full justify-between gap-4 mt-3 px-4 pb-3 flex-col ">
                        <div className="overflow-hidden text-white  text-ellipsis text-base font-semibold leading-6 grow shrink basis-auto">
                          <div className="overflow-hidden text-white  text-ellipsis text-base font-semibold leading-6 grow shrink basis-auto">
                            <button
                              onClick={HandleLogout}
                              className="no-underline text-white text-ellipsis"
                            >
                              {loading ? "Loading..." : "Logout"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
