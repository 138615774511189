import React from "react";
import { Navigate } from "react-router-dom";

const AuthRedirect = (props) => {
  const hasToken = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user-verify"));

  if (hasToken && user?.verification === 1) {
    return (
      <Navigate to={{ pathname: "/home", state: { from: props.location } }} />
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};
export default AuthRedirect;
