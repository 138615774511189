import React from "react";
import { Link } from "react-router-dom";

const NotFound = ({ statusCode, Text }) => {
  return (
    <section className="flex items-center h-screen p-16 bg-gray-50 dark:bg-gray-700">
      <div className="container flex flex-col items-center ">
        <div className="flex flex-col gap-6 max-w-md text-center">
          <h2 className="font-extrabold text-9xl text-gray-600">
            <span className="sr-only">Error</span>
            {statusCode || "404"}
          </h2>
          <p className="text-2xl md:text-3xl dark:text-gray-400">
            {Text || "  Sorry, we couldn't find this page."}
          </p>
          <Link
            to="/home"
            className="px-6 py-3 text-xl font-semibold rounded bg-gray-700 text-gray-50 hover:text-gray-200"
          >
            Back to homepage
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
