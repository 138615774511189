import React, { useState, useEffect } from "react";
import { MdOutlineTimerOff } from "react-icons/md";

const QuizTimer = ({ quizTimeLeft, setQuizTimeLeft, onComplete }) => {
  const [showtimer, setShowTimer] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setQuizTimeLeft((time) => {
        if (time <= 1) {
          clearInterval(interval);
          return 0;
        }
        return time - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [quizTimeLeft]);

  const formatquizTime = () => {
    const minutes = Math.floor(quizTimeLeft / 60);
    const seconds = quizTimeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="flex flex-col w-full items-center justify-center ml-16 max-md:ml-0 ">
      <div className="text-2xl font-bold text-black">
        {showtimer ? formatquizTime() : <MdOutlineTimerOff />}
      </div>

      <button
        onClick={() => setShowTimer((showtimer) => !showtimer)}
        className="border text-black border-solid rounded-xl px-6 py-1 mt-2  font-bold"
      >
        {showtimer ? "Hide" : "Show"}
      </button>
    </div>
  );
};

export default QuizTimer;
