import React, { useState, useEffect } from "react";
import TextSelector from "text-selection-react";

const HigthLighter = () => {
  const [color, setColor] = useState("yellow");

  const annoteHandler = (html, text) => {
    setColor("yellowgreen");
    console.log("text 🦄", text);
  };

  useEffect(() => {
    console.log("color -> ", color);
  }, [color]);

  return (
    <TextSelector
      unmark={true}
      unmarkText="remove"
      events={[
        {
          text: "Annotate",
          handler: annoteHandler,
        },
      ]}
      color={color}
      colorText={true}
    />
  );
};

export default HigthLighter;
