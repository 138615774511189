import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { MdOutlineZoomInMap } from "react-icons/md";
import Modal from "react-modal";

Modal.setAppElement("#root");

const ModalContext = ({
  modalIsOpen,
  closeModal,
  contentLabel,
  id,
  iscollapse,
  children,
}) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [open, setOpen] = useState(true);
  const customStyles = {
    content: {
      top: isZoomed ? "0" : "50%",
      left: isZoomed ? "0" : "50%",
      right: isZoomed ? "0" : "auto",
      bottom: isZoomed ? "0" : "auto",
      marginRight: isZoomed ? "0" : "-50%",
      transform: isZoomed ? "" : "translate(-50%, -50%)",
      width: isZoomed ? "98%" : "auto", // Full width when zoomed in
      height: isZoomed ? "100%" : "auto", // Full height when zoomed in
      overflow: isZoomed ? "auto" : "hidden",
    },
  };
  const handleZoomClick = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel={contentLabel}
      id={id}
    >
      {" "}
      {iscollapse ? (
        <button
          className="absolute top-6 z-20  right-36 mt-2 mr-2"
          onClick={handleZoomClick}
        >
          <MdOutlineZoomInMap color="#fff" size={20} />
        </button>
      ) : null}
      {children}
    </Modal>
  );
};

export default ModalContext;
