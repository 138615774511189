import React, { useTransition } from "react";
import VerificationInput from "react-verification-input";
import "./style.css";
import axios from "axios";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
const ForgetVerification = () => {
  const navigation = useNavigate();
  const mail = localStorage.getItem("email");
  const [showPassword, setShowPassword] = React.useState(false);
  const [filter, setFilter] = React.useState({
    password: "",
    verification: "",
  });
  const [pending, startTransation] = useTransition();

  const handleChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };
  const HandleSubmit = () => {
    axios
      .post(
        "https://panel.digitalsatexam.az/api/auth/email-verification-for-forgot-password",
        {
          verify_code: filter.verification,
          password: filter.password,
          email: mail,
        }
      )
      .then((res) => {
        if (res.status === 200) {
          toast.success(res?.data?.message);
          setTimeout(() => {
            navigation("/");
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <form className="max-w-[400px] w-full border p-4 rounded-md shadow-md">
        <div>
          <label className="mb-2 ml-2" htmlFor="verification">
            Verification Code
          </label>
          <VerificationInput
            onChange={(e) => {
              setFilter({
                ...filter,
                verification: e,
              });
            }}
            inputField={{
              onChange: (e) => {
                setFilter({
                  ...filter,
                  verification: e.nativeEvent.target.value,
                });
              },
            }}
            length={6}
            name="verification"
            validChars="0-9"
            removeDefaultStyles
            classNames={{
              container: "container",
              character: "character",
              characterInactive: "character--inactive",
              characterSelected: "character--selected",
              characterFilled: "character--filled",
            }}
          />
          <div>
            <label className="mt-4" htmlFor="password">
              New Password
            </label>
            <div className="border border-slate-800 rounded-md  relative">
              {" "}
              <input
                name="password"
                value={filter.password}
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
                className="border-none focus:outline-none  w-full rounded-md p-2 mt-2"
                placeholder="Enter your new password"
              />
              {showPassword ? (
                <FaRegEyeSlash
                  onClick={handleShowPassword}
                  className="absolute right-3 top-4 text-slate-800"
                />
              ) : (
                <FaRegEye
                  onClick={handleShowPassword}
                  className="absolute right-3 top-4 text-slate-800"
                />
              )}
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            startTransation(() => {
              HandleSubmit();
            });
          }}
          disabled={pending}
          className="bg-blue-800 w-full flex justify-center items-center text-white rounded-md p-2 mt-3"
        >
          Change Password
        </button>
      </form>
    </div>
  );
};

export default ForgetVerification;
