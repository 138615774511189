import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import About from "./pages/About";
import Authmiddleware from "./middleware/CheckAuthintatication";
import Layout from "../src/featured/Layout/index";
import AuthRedirect from "./middleware/AuthRedirect";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentErrorPage from "./pages/PaymentError/PaymentError";
import Result from "./components/Quiz/Result";
import UserExams from "./pages/UserExams/UserExams";
import CloseQuiz from "./pages/CloseQuiz";
import ResultDetail from "./pages/ResultDetail/ResultDetail";
import ForgetPassword from "./pages/ForgetPassword";
import LoginVerification from "./pages/Verification/LoginVerification";
import ForgetVerification from "./pages/Verification/ForgetVerification";
const PrivateRoutes = [
  {
    path: "/home",
    element: (
      <Authmiddleware>
        <Layout>
          <Home />
        </Layout>
      </Authmiddleware>
    ),
  },
  {
    path: "/about",
    element: (
      <Authmiddleware>
        <Layout>
          <About />
        </Layout>
      </Authmiddleware>
    ),
  },

  {
    path: "/result",
    element: (
      <Authmiddleware>
        <Layout>
          <Result />
        </Layout>
      </Authmiddleware>
    ),
  },
  {
    path: "/result/:id",
    element: (
      <Authmiddleware>
        <Layout>
          <ResultDetail />
        </Layout>
      </Authmiddleware>
    ),
  },
  {
    path: "/user-exams/:id",
    element: (
      <Authmiddleware>
        <CloseQuiz />
      </Authmiddleware>
    ),
  },
  {
    path: "/user-exams",
    element: (
      <Authmiddleware>
        <Layout>
          <UserExams />
        </Layout>
      </Authmiddleware>
    ),
  },
  {
    path: "/payment-success",
    element: (
      <Authmiddleware>
        <PaymentSuccess />
      </Authmiddleware>
    ),
  },
  {
    path: "/payment-error",
    element: (
      <Authmiddleware>
        <PaymentErrorPage />
      </Authmiddleware>
    ),
  },
];
const PublicRoutes = [
  {
    path: "/register",
    element: (
      <AuthRedirect>
        <Register />
      </AuthRedirect>
    ),
  },
  {
    path: "/",
    element: (
      <AuthRedirect>
        <Login />
      </AuthRedirect>
    ),
  },
  {
    path: "/verify-registration",
    element: (
      // <AuthRedirect>
      <LoginVerification />
      // </AuthRedirect>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <AuthRedirect>
        <ForgetPassword />
      </AuthRedirect>
    ),
  },

  {
    path: "/password-verifcation",
    element: (
      <AuthRedirect>
        <ForgetVerification />
      </AuthRedirect>
    ),
  },
];
export { PrivateRoutes, PublicRoutes };
