import React from "react";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import AnimationUserExam from "../../assets/anime2.json";
const EmptyExams = () => {
  return (
    <div className="container my-5">
      <div className="row items-center justify-center">
        <div className="col-xl-5">
          <div className="text-center p-4 ">
            <div className="">
              {" "}
              <Lottie
                style={{
                  width: "100%",
                  height: "200px",
                  maxWidth: "200px",
                  margin: "0 auto",
                }}
                animationData={AnimationUserExam}
                loop={true}
              />
            </div>
            <h1 className="text-2xl">
              There is no exam yet. Please, buy an exam.
            </h1>
            <button>
              <Link
                className="bg-gray-700 hover:bg-gray-800 my-4 block text-white font-bold py-2 px-4 rounded"
                to="/home"
              >
                Exams{" "}
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyExams;
